<template>

  <div>
    <ft-select @input="changeMono($event)" :items="CHOICE_ACTIVE" v-model="liqPayOn" class="pointer">
      <v-chip
          dark
          :color="wsACCENT"
          :style="!liqPayOn ? 'opacity : 0.5' : ''"
          class="px-1 justify-end font-weight-medium pointer"
      >

        <v-icon  class="mr-1" >
          {{  liqPayOn ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
        </v-icon>

        <h5 class="mx-2">{{ liqPayOn ? $t('Active') : $t('NotActive')   }}</h5>

        <v-icon >mdi-menu-down</v-icon>

      </v-chip>
    </ft-select>

    <wsDialog v-if="displayMono"
              v-model="displayMono"
              :title="$t('MonobankSettings')"
              @save="linkMono"
              :loading="LOADING"
              :save-text="$t('ConfirmLink')">

      <ws-text-field
          v-model="mono.token"
          :placeholder="$t('Token') "
          :label="$t('Token') "
          dense
          outlined
          hide-details />

    </wsDialog>

  </div>



</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "liqPaySettings",
  props : {
    value : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      liqPayOn : false,
      displayMono : false,
      mono : {
        token   : null,
      }
    }
  },
  watch: {
    value() {
      if ( this.value !== this.liqPayOn ) {
        this.liqPayOn = this.value
      }
    }
  },
  methods : {
    ...mapActions('monobank', [
      'LINK_BUSINESS',
      'UNLINK_BUSINESS'
    ]),

    async changeMono(value) {

      if ( !value ) {
        return this.unlinkMono()
      }

      this.liqPayOn = false
      this.liqPay = {
        public_key   : null,
        private_key  : null,
      }
      this.displayMono = true

    },

    async unlinkMono() {

      if (await this.UNLINK_BUSINESS() ) {
        this.notify(this.$t('MonoBankUnlinked'))
        this.$emit('input',false)
        return
      }
    },
    async linkMono() {

      if (! await this.LINK_BUSINESS(this.mono) ) {
        return this.notify(this.$t('WrongMonoBankToken'))
      }

      this.$emit('input',true)
      this.notify( this.$t('MonoBankConnected') )
      this.displayMono = false

    },
  },
  mounted() {
    if ( this.value ) {
      this.liqPayOn = this.value
    }
  }
}
</script>

<style scoped>

</style>